import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Table, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setQuery, setSelect } from './../store/reducers/dataSlice';
import EmptyTable from '../pages/messages/EmptyTable';
import { useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';

const customSearchElement = (dataIndex, handleSearch, title) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    // clearFilters,
  }) => {
    return (
      <div style={{ padding: 10 }}>
        <Input
          placeholder={`Search ${title || dataIndex}`}
          defaultValue={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value.trim()] : [])
          }
          onPressEnter={() =>
            handleSearch(
              selectedKeys?.[0] ? [selectedKeys[0].trim()] : [],
              confirm,
              dataIndex,
            )
          }
          style={{ marginBottom: 8, display: 'block' }}
          ref={(input) => input && input.focus()}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                selectedKeys?.[0] ? [selectedKeys[0].trim()] : [],
                confirm,
                dataIndex,
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    );
  },
  filterIcon: (filtered) => (
    <Tooltip title="search">
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    </Tooltip>
  ),
});

const makeSearchable = (columns, storageName, handleSearch) => {
  const storageData = localStorage.getItem(storageName);
  const filterData = storageData ? JSON.parse(storageData) : null;
  let searchableColumns = columns.map((item) => {
    if (item.searchable && !item.filters)
      return {
        ...item,
        ...customSearchElement(item.dataIndex, handleSearch, item.title),
        filteredValue: null,
      };
    else
      return {
        ...item,
        filteredValue: null,
      };
  });
  if (
    filterData?.searchState?.columnKey &&
    filterData?.searchState?.searchText
  ) {
    searchableColumns = _.map(searchableColumns, function (a) {
      return a.key === filterData?.searchState?.columnKey
        ? { ...a, filteredValue: filterData?.searchState?.searchText }
        : a;
    });
  }
  return searchableColumns;
};

function MyTable(props) {
  let { pathname } = useLocation();
  const { id } = useParams();
  const state = localStorage.getItem('state');
  const user = JSON.parse(state)?.user;
  let storageName = `filterData-${pathname}-${user?.id}`.replace('/', '');
  let storageNamePage = `pageData-${pathname}-${user?.id}`.replace('/', '');
  const [page, setPage] = useState();
  const [sortAndPageData, setSortAndPageData] = useState({
    sorter: {},
    pagination: { current: 1, _limit: props.hidePagination ? 9000 : 15 },
  });
  const [searchState, setSearchState] = useState(null);
  const customColumns = props.columns; //.map((el) => ({ ...el, filteredValue: filteredValue[el?.dataIndex] || null }));
  const paginationSetting = {
    simple: true,
    position: ['bottomCenter'],
    className: 'remove-onPrint',
    total: props.count,
    pageSize: 15,
    current: page,
    onChange: (page) => {
      setPage(page);
    },
  };

  useEffect(() => {
    applyFiltersAndSorter(storageName, setSortAndPageData, setSearchState);
  }, [page]);

  useEffect(() => {
    const currentPageDate = localStorage.getItem(storageNamePage);
    const filterData = currentPageDate ? JSON.parse(currentPageDate) : null;
    setPage(filterData?.page || 1);
  }, []);

  function findLocation(columns, dataIndex) {
    let res = columns.filter((item) => {
      return item.dataIndex === dataIndex;
    });
    if (res[0]) {
      if (res[0].filters) return res[0].location ? res[0].location : dataIndex;
      else
        return res[0].location
          ? `${res[0].location}_contains`
          : `${dataIndex}_contains`;
    } else {
      return null;
    }
  }

  const handleSortAndPageChange = (pagination, filters, sorter) => {
    let numberOfFilters = 0;
    let filterState;
    for (const item in filters) if (filters[item]) numberOfFilters++;
    for (const item in filters)
      if (
        filters[item] &&
        (searchState?.columnKey !== item || numberOfFilters === 1)
      ) {
        filterState = {
          searchText: filters[item],
          searchedColumn: findLocation(customColumns, item),
          columnKey: item,
        };
      } else if (numberOfFilters !== 1) {
        setSearchState({
          searchText: '',
          searchedColumn: '',
        });
      }
    localStorage.setItem(
      storageName,
      JSON.stringify({ sortAndPageData, searchState: filterState }),
    );
    setSearchState(filterState);
    setSortAndPageData({
      pagination,
      sorter,
    });
  };

  const handleQuery = (pagination, filters, sorter, columns) => {
    let sort = '';
    if (sorter.column)
      if (sorter.column.filters)
        sort = sorter.column
          ? `&_sort=${sorter.column.sortLocation}:${
              sorter.column
                ? sorter.order.localeCompare('ascend')
                  ? 'DESC'
                  : 'ASC'
                : props.defSortKey || 'id:DESC'
            }`
          : '';
      else
        sort = sorter.column
          ? `&_sort=${
              sorter.column.location
                ? `${sorter.column.location}`
                : sorter.field
            }:${
              sorter.column
                ? sorter.order.localeCompare('ascend')
                  ? 'DESC'
                  : 'ASC'
                : props.defSortKey || 'id:DESC'
            }`
          : '';

    let search = (filters?.searchText || [])
      .map((el) => `&${filters?.searchedColumn}=${el}`)
      ?.join('');

    let query = `&_start=${(page - 1) * 15 || 0}${search}${
      sort ? sort : `&_sort=${props.defSortKey || 'id:DESC'}`
    }`;
    props.setQuery(query);
  };

  useEffect(() => {
    handleQuery(
      sortAndPageData?.pagination,
      searchState,
      sortAndPageData?.sorter,
      customColumns,
    );
  }, [sortAndPageData, searchState]);

  useEffect(() => {
    const localStorageItems = { ...localStorage };
    if (!id) {
      const localStorageKeys = _.keys(localStorageItems);
      _.map(localStorageKeys, (item) => {
        if (
          !item.startsWith(`filterData-${pathname}`.replace('/', '')) &&
          item.startsWith(`filterData-`)
        ) {
          localStorage.removeItem(item);
        }
        if (
          !item.startsWith(`pageData-${pathname}`.replace('/', '')) &&
          item.startsWith(`pageData-`)
        ) {
          localStorage.removeItem(item);
        }
      });
    }
    localStorage.setItem(
      storageName,
      JSON.stringify({ sortAndPageData, searchState }),
    );
  }, [sortAndPageData, searchState]);

  useEffect(() => {
    localStorage.setItem(storageNamePage, JSON.stringify({ page }));
  }, [page]);

  function handleSearch(selectedKeys, confirm, dataIndex) {
    setSearchState({
      searchText: selectedKeys,
      searchedColumn: findLocation(customColumns, dataIndex),
      columnKey: dataIndex,
    });
    confirm();
  }
  return (props.data && props.data.length) || props.query.replace('&', '') ? (
    <Table
      id="myTable"
      showSorterTooltip={true}
      locale={{
        emptyText: 'No Data Available',
        triggerDesc: 'descend sort',
        triggerAsc: 'ascend sort',
        cancelSort: 'cancel sort',
      }}
      bordered={true}
      pagination={!props.hidePagination && paginationSetting}
      onChange={(pagination, filters, sorter, ...rest) => {
        handleSortAndPageChange(pagination, filters, sorter, customColumns);
      }}
      loading={props.loading}
      rowKey={'id'}
      columns={makeSearchable(customColumns, storageName, handleSearch)}
      dataSource={props.dataSource || props.data}
      style={{ marginTop: '40px' }}
      scroll={props.scroll || {}}
    />
  ) : (
    <EmptyTable title={props.title} link={props.link} />
  );
}

function applyFiltersAndSorter(
  storageName,
  setSortAndPageData,
  setSearchState,
) {
  const storageData = localStorage.getItem(storageName);
  const filterData = storageData ? JSON.parse(storageData) : null;
  if (filterData) {
    if (filterData?.sortAndPageData) {
      setSortAndPageData(filterData?.sortAndPageData);
    }
    if (filterData?.searchState) {
      setSearchState(filterData?.searchState);
    }
  }
}

function mapStateToProps(state) {
  return {
    loading: state.data.isLoading,
    data: state.data.data,
    count: state.data.count,

    query: state.data.query,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setQuery: (q) => dispatch(setQuery(q)),
    setSelect: (q) => dispatch(setSelect(q)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTable);
